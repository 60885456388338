import { NotificationManager } from "react-notifications";
import { Link } from "react-scroll";

import "./NavBar.scss";

import topIcon1 from "../../assets/img/icons/topIcon1.svg";
import topIcon2 from "../../assets/img/icons/topIcon2.svg";
import topIcon3 from "../../assets/img/icons/topIcon3.svg";
import topIcon4 from "../../assets/img/icons/topIcon4.png";

import menuIcon from "../../assets/img/icons/menu.svg";

const menu = ["about", "rarity", "purpose", "team"];

const TopLogo = () => {
  return (
    <div className="navBar__logo">
      <div className="navBar__logo__text">dreamy geeks</div>
    </div>
  );
};

const SectionMenu = () => {
  return (
    <div className="navBar__sectionMenu">
      {menu.map((item, index) => (
        <Link key={index} smooth={true} duration={500} spy={true} to={item}>
          {item.toUpperCase()}
        </Link>
      ))}
    </div>
  );
};

const DropSectionMenu = () => {
  return menu.map((item, index) => (
    <Link key={index} smooth={true} duration={500} spy={true} to={item}>
      {item.toUpperCase()}
    </Link>
  ));
};

const TopButtons = ({ walletAddress, onConnectWalletHandler }) => {
  const connectWallet = () => {
    NotificationManager.info(
      "Metamask Wallet connection currently unavailable! Please retry on launch date."
    );
  };

  return (
    <div className="navBar__menu">
      <button
        className="navBar__menu__walletBtn"
        onClick={() => onConnectWalletHandler()}
      >
        {walletAddress === "" ? "CONNECT WALLET" : walletAddress}
      </button>
      <div className="navBar__menu__icons">
        <a href="http://discord.dreamygeeks.com">
          <img src={topIcon1} alt="logo"></img>
        </a>
        <a href="https://twitter.com/DreamyGeeks">
          <img src={topIcon2} alt="logo"></img>
        </a>
        <a href="https://instagram.com/DreamyGeeks">
          <img src={topIcon3} alt="logo"></img>
        </a>
        <a href="https://OpenSea.io/collection/dreamygeeks">
          <img src={topIcon4} alt="logo"></img>
        </a>
      </div>

      <div className="navBar__menu__dropDownMenu">
        <img
          src={menuIcon}
          className="navBar__menu__dropDownMenu__icon"
          alt="menu"
        ></img>
        <div className="navBar__menu__dropDownMenu__content">
          <DropSectionMenu />
          <a href="http://discord.dreamygeeks.com" target="_blank">
            JOIN DISCORD
          </a>
          <a href="https://twitter.com/DreamyGeeks" target="_blank">
            TWITTER
          </a>
          <a href="https://instagram.com/DreamyGeeks" target="_blank">
            INSTAGRAM
          </a>
          <a href="https://opensea.io/collection/dreamygeeks">OPENSEA</a>
        </div>
      </div>
    </div>
  );
};

export const NavBar = ({ walletAddress, onConnectWalletHandler }) => {
  return (
    <div className="navBar container">
      <TopLogo />
      <SectionMenu />
      <TopButtons
        walletAddress={walletAddress}
        onConnectWalletHandler={onConnectWalletHandler}
      />
    </div>
  );
};

export default NavBar;
