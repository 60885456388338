import { Link } from 'react-scroll'

import './Footer.scss';
import polygonscan from '../../assets/img/Verified_Smart_Contract_Polygonscan.png';
import opensea_polygon from '../../assets/img/OpenSea_Polygon_Collection_Button.png';
import etherscan from '../../assets/img/Verified_Smart_Contract_Etherscan.png';
import opensea_eth from '../../assets/img/OpenSea_ETH_Collection_Button.png';
import { ENVS } from "../../helpers/configurations/index";
const menu = [
    "about", "rarity", "purpose", "team"
];

export const Footer = () => {

    const scanUrl = ENVS.CHAIN_ID == "137" ? "https://polygonscan.com/address" : "https://mumbai.polygonscan.com/address" ;

    return (
        <section className="footer container">
            <div className="footer__buttons">
                <div className="footer__buttons__item">
                <a href={`${scanUrl}/${ENVS.CONTRACT_ADDRESS}`} target="_blank"><img alt="pic" src={polygonscan}></img></a>
                </div>
                <div className="footer__buttons__item">
                    <a href="https://OpenSea.io/collection/dreamygeeks" target="_blank"><img alt="pic" src={opensea_polygon}></img></a>
                </div>
                <div className="footer__buttons__item">
                    <a href="https://etherscan.io/address/0x699d0efa91db8ba0128792b1231ee8a478141491" target="_blank"><img alt="pic" src={etherscan}></img></a>
                </div>
                <div className="footer__buttons__item">
                    <a href="https://OpenSea.io/collection/dreamygeeksnft" target="_blank"><img alt="pic" src={opensea_eth}></img></a>
                </div>
            </div>

            <div className="footer__links">
                <div className="footer__links__title">
                    DREAMY GEEKS
                </div>

                <div className="footer__links__buttons">
                    <div className="footer__links__buttons__menu">
                        {
                            menu.map((item, index) => (
                                <Link
                                    key={index}
                                    smooth={true} 
                                    duration={500} 
                                    spy={true} 
                                    to={ item }
                                >
                                    { item.toUpperCase() }
                                </Link>
                            ))
                        }
                    </div>

                    <div className="footer__links__buttons__menu">
                        <a href="http://discord.dreamygeeks.com">JOIN DISCORD</a>
                        <a href="https://twitter.com/DreamyGeeks">TWITTER</a>
                        <a href="https://instagram.com/DreamyGeeks">INSTAGRAM</a>
                        <a href="#javascript;">FACEBOOK</a>
                    </div>
                </div>
            </div>

            <div className="footer__copyright">
                © 2022 DREAMY GEEKS. ALL RIGHTS RESERVED
            </div>
        </section>
    )
}

export default Footer;