import './About.scss';

import back from '../../assets/img/DG_About.jpg';

export const About = () => {
    return (
        <section className="about" id="about">
            <div className="about__back">
                <img src={back} alt="pic"></img>
                <center>
                <h1>Dreamy Geeks Press and Interviews</h1> 
                </center>
                
                <center>
                <iframe height="200px" width="30%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/09119743-eb04-4386-a45b-b8b7d4b94b86?dark=false"></iframe>
                </center>

            </div>
        </section>
    )
}


export default About;