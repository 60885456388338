export const ROUTERS = {
    DASHBOARD: "/",
}

export const ENVS = {
    // Ethereum Mainnet
    //CONTRACT_ADDRESS: "0x699d0Efa91DB8Ba0128792B1231Ee8a478141491",
    //CHAIN_ID: "0x1",

    // Rinkeby Testnet
    // CONTRACT_ADDRESS: "0x40D6EC8B51B1874C980a907769de850359028575",
    // CHAIN_ID: "0x4",

    // Matic Mainet
    //FIRST CONTRACT_ADDRESS: "0xa2Ed591138c9D9dC1c532F2C7d60aA809d7d4f96",
        CONTRACT_ADDRESS: "0x1b2Ef2FA531e1A420E57939bd304F0C0895bd00F",
        CHAIN_ID: "137",
    
    // Mumbai Testnet
    // CONTRACT_ADDRESS: "0x5dC110bEdCE8D95D0301ddEA23647AB6E7225FCe",
    // CHAIN_ID: "80001",

    // Kovan Testnet
    // CONTRACT_ADDRESS : "0x9238c202C55d3dAE6dC8d645F7a78c57683A8bcc",
    // CHAIN_ID : "42",

}