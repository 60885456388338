import NavBar from "../../components/NavBar/NavBar";
import Intro from "../../components/Intro/Intro";
import About from "../../components/About/About";
import Slider from "../../components/Slider/Slider";

import Rarity from "../../components/Rarity/Rarity";
import Roadmap from "../../components/Roadmap/Roadmap";
import Team from "../../components/Team/Team";
import Footer from "../../components/Footer/Footer";
import { useState, useEffect } from "react";
import { mintNFT, mintFeeNFT } from "../../helpers/interact";
import { connectWallet, getCurrentWalletConnected } from "../../helpers/wallet";
import { getIsWhiteList, getCurrentTotalSupply, getCurrentMaxSupply } from "../../helpers/contract";
import { NotificationManager } from "react-notifications";

export const Home = () => {
  const [isWhiteList, setIsWhiteList] = useState(false);
  const [mintLoading, setMintLoading] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [totalSupply, setTotalSupply] = useState(0);
  const [maxSupply, setMaxSupply] = useState(0)
  const [status, setStatus] = useState("");

  useEffect(() => {
    const initDatas = async () => {
      if (window.ethereum) {
        const { address, status } = await getCurrentWalletConnected();
        setWalletAddress(address);
        setStatus(status);
        onChangeWalletListener();
        onConnectWalletHandler();
        //const totalSupply = await 
        setTotalSupply(await getCurrentTotalSupply())
        setMaxSupply(await getCurrentMaxSupply())
      }
    };

    initDatas();
  }, []);

  const onConnectWalletHandler = async () => {
    if (window.ethereum) {
      const walletResponse = await connectWallet();
      setStatus(walletResponse.status);
      setWalletAddress(walletResponse.address);
      await onWhiteListHandler(walletResponse.address);
    } else {
      NotificationManager.success(
        "🦊 You must install Metamask in your browser"
      );
    }
  };

  const onChangeWalletListener = () => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length) {
          setWalletAddress(accounts[0]);
          onWhiteListHandler(accounts[0]);
          setStatus("Get your DreamyGeek, 57 MATIC");
        } else {
          setWalletAddress("");
          setStatus("Connect Metamask");
        }
      });

      window.ethereum.on("chainChanged", (chainId) => {
        onConnectWalletHandler();
      });
    } else {
      setStatus(
        <p>
          🦊 You must install Metamask, a virtual Ethereum wallet, in your
          browser.(https://metamask.io/download.html)
        </p>
      );
    }
  };

  const onWhiteListHandler = async (walletAddress) => {
    const whiteNum = await getIsWhiteList(walletAddress);
    setIsWhiteList(whiteNum > 0 ? true : false);
  };

  const onMintHandler = async (amount) => {
    if (!!walletAddress) {
      setMintLoading(true);

      if (isWhiteList) {
        const { success, status } = await mintFeeNFT(
          walletAddress,
          setMintLoading,
          amount
        );
        if (success) {
          setStatus("Congratulations. You've successfully minted!");
          NotificationManager.success(
            "Congratulations. You've successfully minted!"
          );
        } else if (status.indexOf("insufficient fund") >= 0) {
          setStatus("Info", "You don't have enough to mint a Dreamy Geek!");
          NotificationManager.info(
            "Info. You don't have enough to mint a Dreamy Geek!"
          );
        } else if (status.indexOf("presale is not open") >= 0) {
          NotificationManager.info("Presale is not open!");
        } else if (
          status.indexOf("this address is not whitelisted for the presale") >= 0
        ) {
          NotificationManager.info(
            "This address is not whitelisted for the presale!"
          );
        } else if (
          status.indexOf(
            "this address is not allowed to mint that many during the presale") >= 0
        ) {
          NotificationManager.info(
            "This address is not allowed to mint that many during the presale!"
          );
        } else if (
          status.indexOf("this stakeholder is not allowed to mint that many") >= 0
        ) {
          NotificationManager.info(
            "This stakeholder is not allowed to mint that many for free!"
          );
        } else {
          NotificationManager.info("Transaction has failed !");
        }
      } else {
        const { success, status } = await mintNFT(
          walletAddress,
          setMintLoading,
          amount
        );
        //console.log(status);
        if (success) {
          setStatus("Congratulations", "You've successfully minted!");
          NotificationManager.success(
            "Congratulations. You've successfully minted!"
          );
        } else if (status.indexOf("insufficient fund") >= 0) {
          setStatus("Info", "You don't have enough to mint Dreamy Geek!");
          NotificationManager.info(
            "You don't have enough to mint a Dreamy Geek!"
          );
        } else if (status.indexOf("presale is not open") >= 0) {
          NotificationManager.info("Presale is not open!");
        } else if (
          status.indexOf("this address is not whitelisted for the presale") >= 0
        ) {
          NotificationManager.info(
            "This address is not whitelisted for the presale!"
          );
        } else if (
          status.indexOf(
            "this address is not allowed to mint that many during the presale") >= 0
        ) {
          NotificationManager.info(
            "This address is not allowed to mint that many during the presale!"
          );
        } else {
          NotificationManager.info("Transaction has failed!");
        }
      }
    }
  };

  return (
    <div>
      <NavBar
        walletAddress={walletAddress}
        onConnectWalletHandler={onConnectWalletHandler}
      />
      <Intro
        walletAddress={walletAddress}
        onConnectWalletHandler={onConnectWalletHandler}
        mintLoading={mintLoading}
        onMintHandler={onMintHandler}
        maxSupply = {maxSupply}
        totalSupply={totalSupply}
      />
      <About />
      <Slider />
      <Rarity />
      <Roadmap />
      <Team />
      <Footer />
    </div>
  );
};

export default Home;
