import "./Intro.scss"

//import banner from "../../assets/img/banner image-01.jpg"
import banner from "../../assets/img/DG_Banner.png"
import dropBack from "../../assets/img/2_Geeks_With_Stars.png"
//import dropDate from "../../assets/img/drop dates2.png"
import minus from "../../assets/img/minus.svg"
import plus from "../../assets/img/plus.svg"
import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"

export const Intro = ({
  walletAddress,
  onConnectWalletHandler,
  mintLoading,
  onMintHandler,
  price = 57,
  maxSupply = 10000,
  totalSupply = '',
}) => {
  const [number, setNumber] = useState()
  // set useState(57) to equal 57 MATIC on home page
  const [total, setTotal] = useState('MINTED OUT')
  const actionMinus = () => {
    let index = number - 1
    setNumber(index)
    // set index * 57 to subtract
    setTotal(Math.round (index * 0 * 10000) /10000 )
  }

  const actionPlus = () => {
    let index = number + 1 
    setNumber(index)
    // set index * 57 to add
    setTotal(Math.round (index * 0 * 10000) /10000 )
  }

  const mintAction = () => {
    onMintHandler(number)
  }

  return (
    <section className="intro" id="intro">
      
      <div className="intro__banner">
        <img alt="pic" src={banner}></img>
      </div>

      <div className="intro__drop">
        <div className="intro__drop__back">
          <img alt="pic" src={dropBack}></img>
        </div>

        <div className="intro__drop__date">
          <div className="intro__drop__date__mint">
            <div className="intro__drop__date__minter flex">
              <button
                className="intro__drop__date__mint__minus"
                onClick={() => {
                  //return number > 1 ? actionMinus() : null
                }}
              >
                
              </button>
              <span className="intro__drop__date__mint__value">{number}</span>
              <button
                className="intro__drop__date__mint__plus"
                //onClick={() => (number < 10 ? actionPlus() : null)}
              >
                
              </button>
            </div>
            
            <button
              className="intro__drop__date__mint__place flex"
              onClick={() => {
                if (!mintLoading) mintAction()
              }}
            >
              {mintLoading && (
                <FontAwesomeIcon
                  className="mint-spinner"
                  icon={faSpinner}
                  size="0.2x"
                />
              )}
              <del>MINT</del>
            </button>
            <div className="intro__drop__date__price">
              {total} 
            </div>
            <div className="intro__drop__date__remain">
              
            </div>
          </div>

         
        </div>
      </div>
    </section>
  )
}

export default Intro
