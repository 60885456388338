import './Roadmap.scss';
import back from '../../assets/img/DG_Purpose_Mission.png';

export const Rarity = () => {
    return (
        <section className="roadmap" id="roadmap">
            <div className="roadmap__back">
                <img src={back} alt="pic"></img>
            </div>
        </section>
    )
}

export default Rarity;